import React, { Suspense, useEffect, useRef, useState } from "react";

import { Canvas, useFrame } from "react-three-fiber";
import { useFBXLoader, OrbitControls, Ring } from "drei";

// import lint from "./Assets/3D/lint.mov";
// import head from "./Assets/3D/toedeloe.mov";
import hetModel from "./Assets/3D/het sout.fbx";
import "./App.scss";

import { motion } from "framer-motion";

function Het({ rotation, position, speed }) {
  const fbx = useFBXLoader(hetModel);
  const het = useRef();
  const inner = useRef();
  // const outer = useRef();

  useFrame(() => {
    het.current.scale.x += 0.0003;
    het.current.scale.y += 0.0003;
    het.current.scale.z += 0.0003;
    inner.current.position.y = -15;
    inner.current.rotation.y += 0.001;
    // inner.current.rotation.y += 0.011;
    // inner.current.rotation.x += 0.005;
    inner.current.rotation.y += 0.011 - inner.current.scale.x * 0.01;
    inner.current.rotation.x += 0.005 - inner.current.scale.x * 0.01;
  });

  return (
    <group ref={het} scale={[0, 0, 0]} rotation={rotation} position={position}>
      {/* <mesh ref={outer} castShadow receiveShadow {...fbx.children[0]}></mesh> */}
      <mesh ref={inner} castShadow receiveShadow {...fbx.children[1]}>
        <meshStandardMaterial
          // wireframe={true}
          fog={true}
          color="grey"
          reflectivity={0.05}
        ></meshStandardMaterial>
      </mesh>
    </group>
  );
}

let hets = [<Het key={Math.random()}></Het>];

function App() {
  const [spawn, setspawn] = useState(0);
  const [scroll, setscroll] = useState(0);
  const transition = { ease: [0.5, 0.0, 0, 0.95] };
  const nameVar = {
    initial: {
      // background: "white",
      padding: "2em",
    },
    animate: {
      padding: "0em",
      transition: {
        // duration: 1,
        delayChildren: 0.6,
        staggerChildren: 0.1,
        staggerDirection: 1,
      },
    },
  };
  const nameVar2 = {
    initial: {
      // background: "white",
      padding: "2em",
    },
    animate: {
      padding: "0em",
      transition: {
        // duration: 1,
        delayChildren: 0.6,
        staggerChildren: 0.1,
        staggerDirection: 1,
      },
    },
  };
  const letterVar = {
    initial: {
      position: "relative",
      // top: 20,
      opacity: 0,
    },
    animate: {
      // top: 0,
      opacity: 1,
      transition: { duration: 10, ...transition },
    },
  };
  const letterVar2 = {
    initial: {
      position: "relative",
      top: "50vh",
      opacity: 0,
    },
    animate: {
      top: "0.55em",
      opacity: 1,
      transition: { duration: 0.1, ...transition },
    },
  };

  useEffect(() => {
    hets.push(
      <Het
        key={Math.random()}
        rotation={[Math.PI / Math.random(), 0, 0]}
        position={[
          window.innerWidth > 600
            ? (Math.random() * 2 - 1) * 100
            : (Math.random() * 2 - 1) * 25,
          window.innerWidth > 600
            ? (Math.random() * 2 - 1) * 100
            : (Math.random() * 2 - 1) * 50,
          window.innerWidth > 600
            ? (Math.random() * 2 - 1) * 100
            : (Math.random() * 2 - 1) * 50,
        ]}
      ></Het>
    );
  }, [spawn]);

  // document.addEventListener("wheel", () => console.log("hoi"));
  return (
    <div
      className="App"
      // onWheel={(e) => setscroll(scroll + (e.deltaY / e.deltaY) * 0.02)}
    >
      <header>
        <motion.div
          id="logo"
          initial="initial"
          animate="animate"
          onMouseDown={() => window.scrollTo(0, 1000)}
        >
          <motion.span id="name" variants={nameVar}>
            <motion.span variants={letterVar}>B</motion.span>
            <motion.span variants={letterVar}>A</motion.span>
            <motion.span variants={letterVar}>R</motion.span>
            <motion.span variants={letterVar}>T</motion.span>
            <motion.span variants={letterVar}>&nbsp;</motion.span>
            <motion.span variants={letterVar}>K</motion.span>
            <motion.span variants={letterVar}>I</motion.span>
            <motion.span variants={letterVar}>P</motion.span>
            <motion.span variants={letterVar}>P</motion.span>
            <motion.span variants={letterVar}>I</motion.span>
            <motion.span variants={letterVar}>N</motion.span>
            <motion.span variants={letterVar}>
              G <br></br>
            </motion.span>
          </motion.span>
          <motion.span id="slogan" variants={nameVar2}>
            <motion.span variants={letterVar2}>'</motion.span>
            <motion.span variants={letterVar2}>E</motion.span>
            <motion.span variants={letterVar2}>x</motion.span>
            <motion.span variants={letterVar2}>p</motion.span>
            <motion.span variants={letterVar2}>e</motion.span>
            <motion.span variants={letterVar2}>r</motion.span>
            <motion.span variants={letterVar2}>i</motion.span>
            <motion.span variants={letterVar2}>e</motion.span>
            <motion.span variants={letterVar2}>n</motion.span>
            <motion.span variants={letterVar2}>c</motion.span>
            <motion.span variants={letterVar2}>e</motion.span>
            <motion.span variants={letterVar2}>&nbsp;</motion.span>
            <motion.span variants={letterVar2}>d</motion.span>
            <motion.span variants={letterVar2}>e</motion.span>
            <motion.span variants={letterVar2}>s</motion.span>
            <motion.span variants={letterVar2}>i</motion.span>
            <motion.span variants={letterVar2}>g</motion.span>
            <motion.span variants={letterVar2}>n</motion.span>
            <motion.span variants={letterVar2}>&nbsp;</motion.span>
            <motion.span variants={letterVar2}>&</motion.span>
            <motion.span variants={letterVar2}>&nbsp;</motion.span>
            <motion.span variants={letterVar2}>I</motion.span>
            <motion.span variants={letterVar2}>n</motion.span>
            <motion.span variants={letterVar2}>t</motion.span>
            <motion.span variants={letterVar2}>e</motion.span>
            <motion.span variants={letterVar2}>r</motion.span>
            <motion.span variants={letterVar2}>a</motion.span>
            <motion.span variants={letterVar2}>c</motion.span>
            <motion.span variants={letterVar2}>t</motion.span>
            <motion.span variants={letterVar2}>i</motion.span>
            <motion.span variants={letterVar2}>v</motion.span>
            <motion.span variants={letterVar2}>e</motion.span>
            <motion.span variants={letterVar2}>&nbsp;</motion.span>
            <motion.span variants={letterVar2}>v</motion.span>
            <motion.span variants={letterVar2}>i</motion.span>
            <motion.span variants={letterVar2}>s</motion.span>
            <motion.span variants={letterVar2}>u</motion.span>
            <motion.span variants={letterVar2}>a</motion.span>
            <motion.span variants={letterVar2}>l</motion.span>
            <motion.span variants={letterVar2}>s</motion.span>
            <motion.span variants={letterVar2}>'</motion.span>
          </motion.span>
          {/* <p id="name">BART KIPPING</p>
          <p id="slogan">
            <span className="line">'Experience design &&nbsp;</span>
            <span className="line">Interactive visuals'</span>
          </p> */}
        </motion.div>
      </header>

      <Canvas
        id="cnv"
        onTouchStart={() => {
          setspawn(spawn + 1);
        }}
        onClick={() => {
          setspawn(spawn + 1);
        }}
        camera={{
          position: [20, -100, -200],
          fov: 30,
        }}
        shadowMap={true}
        colorManagement
      >
        {/* <fog attach="fog" args={["aquamarine", 500, 1500]} /> */}
        <directionalLight
          color="orange"
          castShadow
          intensity={1.5}
          position={[0, 300, 0]}
          shadow-mapSize-width={1024}
          shadow-mapSize-height={1024}
          shadow-camera-far={500}
          shadow-camera-left={-500}
          shadow-camera-right={500}
          shadow-camera-top={500}
          shadow-camera-bottom={-500}
        ></directionalLight>
        <ambientLight color="white" intensity={0.6}></ambientLight>
        <pointLight castShadow position={[0, 0, 0]} color="red"></pointLight>
        <OrbitControls enableZoom={true}></OrbitControls>

        <Suspense fallback={null}>
          {/* <group rotation={[0, scroll, 0]}> */}
          {hets.map((ring, index) => ring)}
          {/* </group> */}
        </Suspense>
        {/* <mesh
          rotation={[-Math.PI / 2, 0, 0]}
          position={[0, -30, 0]}
          receiveShadow
        >
          <planeBufferGeometry attach="geometry" args={[300, 300]} />
          <shadowMaterial attach="material" opacity={0.8} />
        </mesh> */}
      </Canvas>
      <div id="constr">
        {/* <p>
          Under <br></br> Development <br></br> <strong>More soon</strong>{" "}
        </p>{" "} */}
        <p>
          There <br></br> is <br></br> more
        </p>
        <a id="contact" href="mailto:info@bartkipping.nl">
          info@bartkipping.nl
        </a>
      </div>
      {/* <div className="intro">
        <h1>
          I design and develop experiental environments that trigger the process
          of awereness and imagination{" "}
          I use design, technology and human beings to create experiences that
          triggers a sense of awareness and imagination
        </h1>
      </div>
      <div className="videoCarousel">
        <video src={lint} muted autoPlay={true} loop></video>
        <video src={head} muted autoPlay={true} loop></video>
      </div> */}
    </div>
  );
}

export default App;

/* <div id="constr">
        <p>
          Under <br></br> Development <br></br> <strong>More soon</strong>{" "}
        </p>{" "}
        <a id="contact" href="mailto:info@bartkipping.nl">
          info@bartkipping.nl
        </a>
      </div> */
